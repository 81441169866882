ispui-notification-group.main-notification-group {
  --ispui-notification__border: var(--isp-dc-charlie-chaplin) 1px solid;
  --ispui-notification__stack-top_top-stroke: var(--isp-dc-charlie-chaplin);
  --ispui-notification__stack-top_bottom-stroke: var(--isp-dc-charlie-chaplin);
  --ispui-notification__stack-top_top-bg: var(--isp-dc-cow);
  --ispui-notification__stack-top_bottom-bg: var(--isp-dc-cow);
  --ispui-notification__content_padding: 18px 20px 20px;
  position: fixed;
  z-index: 996;
  right: 0;
  bottom: 0;

  box-sizing: content-box;
  ispui-notification {
    --ispui-notification__box-shadow: var(--isp-box-shadow);
    box-sizing: content-box;
    width: 330px;
  }
}
