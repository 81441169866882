:root {
  --ispui-toggle-background-color: var(--isp-dc-monochrome);
  --ispui-toggle-background-color_checked: var(--isp-c-grasshopper);
  --ispui-toggle-border-color_checked: var(--isp-c-grasshopper);
  --ispui-toggle-background-color_theme: var(--isp-c-jon-snow);
  --ispui-toggle-switch-color_checked: var(--isp-c-jon-snow);
  --ispui-toggle-switch-border-color_checked: var(--isp-c-jon-snow);
  --ispui-toggle-background-color_disabled: var(--isp-c-jon-snow);
  --ispui-toggle-border-color: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-color: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-border-color: var(--isp-c-grey-weekdays);
  --ispui-toggle-border-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-border-color_disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-background-color_checked-disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-border-color_checked-disabled: var(--isp-c-grey-weekdays);
  --ispui-toggle-switch-color_checked-disabled: var(--isp-c-jon-snow);
  --ispui-toggle-switch-border-color_checked-disabled: var(--isp-c-jon-snow);
  --ispui-toggle__outline-color: unset;
  --ispui-toggle__label-color: var(--isp-c-malewicz);

  --ispui-toggle-control__border-radius: var(--isp-border-radius-main);
}
