:root {
  // ALL COLORS OF ISPUI-CONFIRM v7.0.1
  --ispui-confirm__text-color: var(--isp-dc-highway-star);
  --ispui-confirm__container-bg-color: var(--isp-dc-cow);
  --isp-color__modal-border: var(--isp-dc-charlie-chaplin);
  --ispui-confirm__container-shadow: var(--isp-c-desolation-of-smaug);
  --ispui-confirm__overlay-background-color: var(--isp-c-desolation-of-smaug);

  --ispui-confirm__box-shadow: var(--isp-box-shadow);

  @media (min-width: 300px) {
    --ispui-confirm__width-small: 320px;
  }

  @media (min-width: 360px) {
    --ispui-confirm__width-small: 360px;
  }

  @media (min-width: 390px) {
    --ispui-confirm__width-small: 390px;
  }

  @media (min-width: 420px) {
    --ispui-confirm__width-small: 420px;
  }
}

.ispui-confirm__container {
  border: 1px solid var(--isp-dc-charlie-chaplin);

  & .ispui-confirm__header,
  & .ispui-confirm__button-group {
    background-color: unset;
  }
}
