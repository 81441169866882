@mixin ispScrollbarGray {
  /** Scrollbar styling for Firefox */
  /** The first applies to the thumb of the scrollbar, the second to the track. */
  scrollbar-color: var(--isp-c-grey-weekdays) var(--isp-dc-charlie-chaplin);
  scrollbar-width: thin;

  /** Scrollbar styling for Chrome */
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    width: 5px;

    border-radius: 10px;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    background-color: var(--isp-dc-charlie-chaplin);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--isp-c-grey-weekdays);

    &:hover {
      background-color: var(--isp-c-blue-velvet);
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

.ispui-scrollbar_gray {
  @include ispScrollbarGray;
}

.isp-toolbar-button-hint {
  a {
    display: block;

    width: fit-content;
    margin-top: 5px;

    text-decoration: none;

    color: var(--isp-c-happy-bob);
  }
}

.isp-usefullink a {
  display: block;

  margin-bottom: 11px;

  text-decoration: none;

  color: var(--isp-dc-fremen);
  &:hover {
    color: var(--isp-dc-lapis-lazuli);
  }
  &:active {
    color: var(--isp-dc-fremen);
  }
}
