/** Styles for user menu dropdown content */
ispui-dropdown-popup {
  --ngispui-vmenu-item__icon-size: 20px;
  --ngispui-vmenu-highlight: var(--isp-c-jon-snow);
  /** for context menu with one  */
  --ispui-dropdown-popup__container_min-height: 55px;

  .ngispui-vmenu-item__icon {
    line-height: 0;
  }
}

.isp-list-table-settings-popup * {
  @include ispScrollbarGray;
}

.isp-dynamic-form-select-dropdown-popup {
  --ispui-dropdown-content-padding: 10px 0 !important;

  max-height: calc(100vh - 40px) !important;
}

.isp-top-bar-menu-dropdown {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-content-padding: 10px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-min-width: 200px;
  --ispui-dropdown-content-display: block;
  --ngispui-vmenu-option__gap: 0;
  --ngispui-vmenu-item__icon-gap: 10px;
  --ngispui-vmenu-highlight: var(--isp-text-color-main);
  --ngispui-vmenu-option__color: var(--isp-text-color-main);
}

.isp-toolbar-dropdown__popup {
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-min-width: 200px;
  --ispui-dropdown-content-padding: 10px;

  .ispui-dropdown-content__wrapper {
    width: 100%;
  }
}

.isp-list-elem-context-menu-dropdown {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-min-width: 245px;
  --ispui-dropdown-max-width: 300px;
  --ispui-dropdown-content-display: block;
}

.isp-list-filter-dropdown-popup {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-footer-padding: 10px 20px 25px 20px;
  --ispui-dropdown-max-width: 622px;

  transform: translateX(30px);

  .ispui-dropdown-content__wrapper {
    --ispui-dropdown-content-pr: 15px;
    overflow: overlay;

    padding-top: 10px;
  }
}

.isp-saved-messages-dropdown__popup {
  --ispui-dropdown-max-width: 600px;
  --ispui-dropdown-content-max-height: 335px;
}

.isp-useful-links__popup {
  .ispui-dropdown-content__wrapper {
    position: relative;

    overflow-y: hidden;

    min-width: 200px;
    padding: 44px 0 0;
  }
  .ispui-dropdown-content {
    max-width: 330px;
    padding: 0 0 5px;
    .isp-usefullinks__title {
      position: absolute;
      top: 0;

      width: 100%;
      height: 44px;
      padding: 15px;

      border-bottom: 1px solid var(--isp-dc-charlie-chaplin);

      font-weight: bold;
    }
    .isp-usefullinks {
      overflow: auto;

      height: 100%;
      max-height: 321px;
      padding: 15px 15px 0;
      @include ispScrollbarGray;
    }
    a {
      display: block;

      margin-bottom: 11px;

      text-decoration: none;

      color: var(--isp-dc-fremen);
    }
    a:hover {
      text-decoration: underline;

      color: var(--isp-c-deep);
    }
    a:active {
      color: var(--isp-dc-fremen);
    }
  }
}

ispui-dropdown-popup.menu__dropdown_popup,
ispui-dropdown-popup.menu__group-dropdown_popup {
  --ispui-dropdown-bg-color: var(--isp-dc-cow);
  --ispui-dropdown-min-height: 45px;
  --ispui-dropdown-min-width: 25px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-content-min-height: 45px;

  .ispui-dropdown-popup__container {
    min-height: 45px;
  }

  .menu-node__side-marked {
    color: var(--isp-dc-fremen);

    &::before {
      content: "";
      position: absolute;
      left: 0;

      width: 4px;
      height: 25px;
      margin-top: -5px;

      background: var(--isp-c-blue-velvet);
    }
  }

  isp-menu-item.menu__node-msg {
    overflow: hidden;

    margin-left: 0;
    .menu-item__item-icon {
      display: inline-block;

      margin-left: 10px;
    }
  }

  .menu-tab-icon {
    --isp-icons-fill-color: transparent;
    --isp-icons-main-color: var(--isp-c-grey-weekdays);

    &:hover,
    &:active {
      --isp-icons-main-color: var(--isp-dc-fremen);
    }
  }
}

ispui-dropdown-popup.menu__dropdown_popup .ispui-dropdown-content {
  --ispui-dropdown-content-padding: 14px 15px;
}

ispui-dropdown-popup.menu__group-dropdown_popup {
  .ispui-dropdown-popup__container {
    min-height: 40px;
  }
  .ispui-dropdown-content__wrapper {
    overflow-y: hidden;
  }
  .ispui-dropdown-content {
    --ispui-dropdown-content-padding: 10px 15px;
    min-height: 40px;
  }
  .menu-item__item-icon {
    display: inline-block;

    margin-left: 10px;

    opacity: 0;
  }
  isp-menu-item:hover .menu-item__item-icon {
    opacity: 1;
  }
  .menu__dropdown-subnode {
    position: static;
  }
  .menu__subnode-item_side-marked {
    &::before {
      content: "";
      position: absolute;
      left: 0;

      width: 4px;
      height: 25px;
      margin-top: -5px;

      background: var(--isp-c-blue-velvet);
    }
  }
  .menu__subnode-item {
    &:first-of-type {
      margin-top: 2px;
    }

    &:last-of-type {
      margin-bottom: 2px;
    }
  }
}
.isp-list-edit-cell-dropdown-popup {
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-content-padding: 0;
  --ispui-dropdown-content-min-height: 30px;
  --ispui-dropdown-popup__container_min-height: 30px;
  --ispui-dropdown-popup__container_box-shadow: none;
  --ispui-dropdown-border: none;
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-bg-color: transparent;

  .ispui-dropdown-content__wrapper {
    width: 100%;
  }
}

.isp-list-column-filter-dropdown-popup {
  --ispui-dropdown-min-width: 200px;
  --ispui-dropdown-max-width: 200px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-content-padding: 0;
  --ispui-dropdown-border-radius: 5px;
}

.isp-color-scheme-dropdown {
  --ispui-dropdown-border-radius: 5px;
  --ispui-dropdown-content-padding: 10px;
  --ispui-dropdown-content-pr: 0;
  --ispui-dropdown-min-width: 150px;
  --ispui-dropdown-content-display: block;
}
