/** overrides for ant date-picker */
.ant-picker {
  height: 30px;
  padding: 0 10px 0 10px;

  border-color: var(
    --isp-date-picker-border-color,
    var(--isp-dc-charlie-chaplin)
  );
}

.ant-picker-wrapper button {
  font-family: "Ubuntu", sans-serif;
}

.ant-picker-input > input {
  color: var(--isp-dc-highway-star);

  font-family: "Ubuntu", sans-serif;
}

.ant-picker-focused {
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-fremen));
}

.ant-picker:hover:not(.ant-picker-focused) {
  border-color: var(
    --isp-date-picker-border-color,
    var(--isp-dc-charlie-chaplin)
  );
}

.ant-picker:hover.ant-picker-focused {
  border-color: var(--isp-date-picker-border-color, var(--isp-dc-fremen));
}

.ant-picker.ant-picker-disabled {
  cursor: default;

  border-color: var(
    --isp-date-picker-border-color,
    var(--isp-dc-charlie-chaplin)
  );
  background-color: var(
    --ispui-input__background-color_disabled,
    var(--isp-c-russian-north)
  );
}

.ant-picker-input > input[disabled] {
  cursor: default;

  color: var(--ispui-input__text-color_disabled, var(--isp-c-grey-weekdays));
}

.ant-picker-clear {
  top: 55%;

  display: grid;

  width: 20px;
  height: 30px;

  place-items: center;

  color: var(--isp-c-grey-weekdays);
  background-color: var(--isp-dc-monochrome);

  &:hover {
    color: var(--isp-c-lovely-puddle);
  }
}

.ant-picker-dropdown {
  color: var(--isp-dc-highway-star);
}

.ant-picker-panel-container {
  border: 1px solid var(--isp-dc-charlie-chaplin);
  border-radius: 5px;

  background: var(--isp-dc-monochrome);
  box-shadow: 0 1px 4px var(--isp-c-desolation-of-smaug);
}

.ant-picker-panel {
  border: none;
}

.ant-picker-panel .ant-picker-footer {
  border-color: var(--isp-dc-charlie-chaplin);
}

.ant-picker-today-btn {
  cursor: pointer;

  color: var(--isp-c-blue-velvet);
}

.ant-picker-header {
  color: var(--isp-dc-fremen);

  border-color: var(--isp-dc-charlie-chaplin);
}

.ant-picker-header button {
  color: var(--isp-dc-charlie-chaplin);
}

.ant-picker-header > button:hover {
  color: var(--isp-dc-fremen);
}

.ant-picker-header .ant-picker-header-prev-btn,
.ant-picker-header .ant-picker-header-next-btn {
  color: var(--isp-c-grey-weekdays);
}

.ant-picker-header-view .ant-picker-header-year-btn,
.ant-picker-header-view .ant-picker-header-month-btn {
  color: var(--isp-dc-fremen);
}

.ant-picker-content th {
  color: var(--isp-dc-highway-star);
}

.ant-picker-cell {
  color: var(--isp-c-grey-weekdays);
}

.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 5px;
}

.ant-picker-cell-in-view {
  color: var(--isp-dc-highway-star);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--isp-dc-fremen);
  border-radius: 5px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: var(--isp-c-jon-snow);
  background: var(--isp-c-blue-velvet);
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: var(--isp-dc-made-in-heaven);
}
