.table-settings-extended-button {
  display: inline-block;

  margin: 18px 0 13px 20px;
  padding: 0;

  cursor: pointer;

  color: var(--isp-dc-fremen);

  border: none;
  background: none;

  font-family: "Ubuntu", sans-serif;

  font-size: 14px;

  &:hover {
    color: var(--isp-dc-lapis-lazuli);
  }

  &:active {
    color: var(--isp-dc-fremen);
  }
}

.column-filter-apply {
  margin-top: 5px;

  border-top: 1px solid var(--isp-dc-charlie-chaplin);
}

.column-filter-apply__button {
  display: inline-block;

  margin: 13px 0 8px 15px;
  padding: 0;

  cursor: pointer;

  color: var(--isp-dc-fremen);

  border: none;
  background: none;

  font-family: "Ubuntu", sans-serif;

  font-size: 14px;

  &:hover {
    color: var(--isp-dc-lapis-lazuli);
  }

  &:active {
    color: var(--isp-dc-fremen);
  }
}
