:root {
  --ispui-validation-error-background-color: var(--isp-dc-new-vegas);
  --ispui-validation-error-box-shadow-color: var(--isp-c-desolation-of-smaug);
  --ispui-validation-error-color: var(--isp-dc-highway-star);
  --ispui-validation-error__close-background-color: var(--isp-c-grey-weekdays);

  --ispui-validation-error__box-shadow: var(--isp-box-shadow);
  --ispui-validation-error-triangle__box-shadow: var(--isp-box-shadow);

  --ispui-validation-error__border-radius: var(--isp-border-radius-main);
  --ispui-validation-error__close-border-radius: var(--isp-border-radius-main);
}
