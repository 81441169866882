:root {
  // ALL COLORS OF ISPUI-CARD v0.2.5
  --ispui-card__background-color: var(--isp-dc-monochrome);

  // @WARN ispui-card have shadow colors, that not customable!

  --ispui-card__border-radius: var(--isp-border-radius-main);

  --ispui-card__box-shadow: var(--isp-box-shadow);
}
