:root {
  --ispui-tooltip-font-main-color: var(--isp-c-jon-snow);
  --ispui-tooltip-background: var(--isp-dc-brotherhood-of-steel);
  --ispui-tooltip-ellipsis__text-overflow: clip;
  --ispui-tooltip-box-shadow: var(--isp-box-shadow);

  .ispui-tooltip a {
    text-decoration: underline;

    color: var(--isp-c-jon-snow);

    font-size: 12px;
  }

  @media (min-width: 960px) {
    --ispui-tooltip-ellipsis__text-overflow: ellipsis;
  }
}
