@import '~ng-zorro-antd/style/patch.less';
/* Import basic styles */
@import '~ng-zorro-antd/date-picker/style/entry.less';

// .less style exist in order to customize ng-zorro-date-picker

@bx-color-link: #1980e5;
@bx-color-active: #dae9fb;
@bx-color-main: #1f3347;
@bx-color-hint_opacity: #8A99a8;
@bx-color-line: #d3d9df;

@primary-color: @bx-color-link;
@outline-color: @bx-color-link;
@heading-color: @bx-color-link;
@picker-date-hover-range-color: @bx-color-active;
@picker-basic-cell-active-with-range-color: @bx-color-active;
@picker-date-hover-range-border-color: @bx-color-line;
@input-border-color: @bx-color-link;
@input-placeholder-color: @bx-color-main;
@text-color: @bx-color-main;
@disabled-color: @bx-color-hint_opacity;
@select-border-color: @bx-color-line;
@input-outline-offset: 0;
@outline-blur-size: 0;
@outline-width: 0;

// @HACK for Safari disabled input
.ant-picker-input > input[disabled] {
  background: var(--ispui-input__background-color_disabled);
}
