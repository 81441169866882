:root {
  // ALL COLORS OF ISPUI-CLOSE v2.0.4

  // ispui-close dark theme don't used in our interface!
  --ispui-close__dark-color: var(--isp-c-malewicz);
  // --ispui-close__dark-background-color_hover: rgba(52, 74, 94, 0.2);
  // --ispui-close__dark-background-color_active: rgba(52, 74, 94, 0.4);

  // ispui-close gray theme don't used in our interface!
  --ispui-close__gray-color: var(--isp-c-grey-weekdays);
  // --ispui-close__gray-background-color_hover: rgba(52, 74, 94, 0.2);
  // --ispui-close__gray-background-color_active: rgba(52, 74, 94, 0.4);

  --ispui-close__light-color: var(--isp-c-russian-north);
  --ispui-close__light-background-color_hover: var(--isp-c-malewicz);
  --ispui-close__light-background-color_active: transparent;

  --ispui-close__border-radius_focus: var(--isp-border-radius-main);

  // @WARN ispui-close have focus state colors, that not customable!
}
