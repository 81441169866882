* {
  box-sizing: border-box;
}

body {
  // THEME DS
  overflow: hidden;

  margin: 0;
  padding: 0;

  color: var(--isp-text-color-main);
  background-color: var(--isp-dc-monochrome);

  font-size: 14px;

  @media (min-width: 640px) {
    --ispui-dropdown-min-width: 150px;
  }
  @media (max-width: 960px) {
    overflow-x: auto;
  }
}

a {
  cursor: pointer;
  text-decoration: none;

  color: var(--isp-dc-fremen);

  font-family: var(--ispui-link__font-family, Ubuntu, sans-serif);
  font-size: var(--ispui-font-size-regular, 14px);

  &:hover {
    text-decoration: underline;

    color: var(--isp-dc-lapis-lazuli);
  }

  &:active {
    text-decoration: underline;

    color: var(--isp-dc-fremen);
  }
}
