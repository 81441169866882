:root {
  --ispui-drawer-breadcrumbs__border-radius: var(--isp-border-radius-main);

  --ispui-drawer__background: var(--isp-dc-monochrome);

  --ispui-drawer_scrollbar-track-width: 5px;
  --ispui-drawer_scrollbar-track-border-radius: 10px;
  --ispui-drawer_scrollbar-track-color: var(--isp-c-inconspicuous);
  --ispui-drawer_scrollbar-thumb-color: var(--isp-c-grey-weekdays);
  --ispui-drawer_scrollbar-thumb-color_hover: var(--isp-c-blue-velvet);

  --ispui-drawer-close__background-color: var(--isp-dc-monochrome);
  --ispui-drawer-close__background-color_hover: var(--isp-dc-monochrome);
  --ispui-drawer-close__border-color: var(--isp-dc-charlie-chaplin);
}
