:root {
  --ispui-notification__b-radius: var(--isp-border-radius-main);
  // info notification
  --ispui-notification__bg-color_info: var(--isp-dc-cow);
  --ispui-notification__border-color_info: var(--isp-dc-charlie-chaplin);
  --ispui-notification__icon-img_info: var(--ispui-banner__icon-img_info);
  // success notification
  --ispui-notification__bg-color_success: var(--isp-dc-cow);
  --ispui-notification__border-color_success: var(--isp-dc-charlie-chaplin);
  --ispui-notification__icon-img_success: var(--ispui-banner__icon-img_success);
  // warning notification
  --ispui-notification__bg-color_warning: var(--isp-dc-new-vegas);
  --ispui-notification__border-color_warning: var(--isp-dc-heliodor);
  --ispui-notification__icon-img_warning: var(--ispui-banner__icon-img_warning);
  // danger notification
  --ispui-notification__bg-color_danger: var(
    --isp-dc-dirty-deeds-done-dirt-cheap
  );
  --ispui-notification__border-color_danger: var(--isp-dc-rhodochrosite);
  --ispui-notification__icon-img_danger: var(--ispui-banner__icon-img_danger);

  --ispui-notification__close-button-bg-color_hover: var(--isp-dc-zebra);
  --ispui-notification__close-button-bg-color_active: var(--isp-dc-zebra);

  ispui-notification[status="warning"],
  ispui-notification[status="danger"] {
    --ispui-notification__close-button-bg-color_hover: var(--isp-dc-glass);
    --ispui-notification__close-button-bg-color_active: var(--isp-dc-glass);
  }
}
