:root {
  // ALL COLORS OF ISPUI-SWITCHER v0.3.0-feature-inputv6-component.0
  --ispiu-swtich__button-border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-switcher__br: var(--isp-border-radius-main);
  --ispiu-swtich__button-bg: var(--isp-dc-monochrome);
  --ispiu-swtich__button-bg-hover: transparent;
  --ispiu-swtich__button-bg_selected: var(--isp-dc-made-in-heaven);
  --ispiu-swtich__button-bg_selected-hover: var(--isp-dc-made-in-heaven);

  // @WARN there is also dropdown items colors not customable
}
