:root {
  --ispui-banner__b-radius: var(--isp-border-radius-main);

  --ispui-banner__bg-color_success: var(--isp-dc-aurora-borealis);
  --ispui-banner__bg-color_warning: var(--isp-dc-new-vegas);
  --ispui-banner__bg-color_danger: var(--isp-dc-dirty-deeds-done-dirt-cheap);
  --ispui-banner__bg-color_info: var(--isp-c-crystal-castles);

  --ispui-banner__icon-img_success: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' fill='%231FAD66'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.48 4.18a.8.8 0 0 1 .14 1.12L7.47 10.6a.9.9 0 0 1-1.28.15L3.58 8.62a.8.8 0 1 1 1.01-1.24l2.06 1.68 3.7-4.75a.8.8 0 0 1 1.13-.13Z' fill='%23fff'/%3E%3C/svg%3E");
  --ispui-banner__icon-img_warning: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' fill='%23FA0'/%3E%3Crect x='8.3' y='11' width='1.6' height='5.5' rx='.8' transform='rotate(-180 8.3 11)' fill='%23fff'/%3E%3Crect x='8.3' y='4.6' width='1.6' height='1.6' rx='.8' transform='rotate(-180 8.3 4.6)' fill='%23fff'/%3E%3C/svg%3E");
  --ispui-banner__icon-img_danger: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.63 1.52a1 1 0 0 1 1.74 0l6.28 10.98a1 1 0 0 1-.87 1.5H1.22a1 1 0 0 1-.87-1.5L6.63 1.52Z' fill='%23E5004C'/%3E%3Crect x='8.3' y='9.5' width='1.6' height='5.5' rx='.8' transform='rotate(-180 8.3 9.5)' fill='%23fff'/%3E%3Crect x='8.3' y='12' width='1.6' height='1.6' rx='.8' transform='rotate(-180 8.3 12)' fill='%23fff'/%3E%3C/svg%3E");
  --ispui-banner__icon-img_info: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z' fill='%231980E5'/%3E%3Crect x='8.3' y='11' width='1.6' height='5.5' rx='.8' transform='rotate(-180 8.3 11)' fill='%23fff'/%3E%3Crect x='8.3' y='4.6' width='1.6' height='1.6' rx='.8' transform='rotate(-180 8.3 4.6)' fill='%23fff'/%3E%3C/svg%3E");
}

.ispui-banner_status_success {
  --ispui-banner__b-color: var(--isp-dc-alexandrite);
}

.ispui-banner_status_warning {
  --ispui-banner__b-color: var(--isp-dc-heliodor);
}

.ispui-banner_status_danger {
  --ispui-banner__b-color: var(--isp-dc-rhodochrosite);
}

.ispui-banner_status_info {
  --ispui-banner__b-color: var(--isp-dc-sodalite);
}

.ispui-banner__button-container .ispui-banner__link {
  color: var(--isp-dc-fremen);

  &:hover {
    color: var(--isp-dc-lapis-lazuli);
  }
}
