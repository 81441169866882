:root {
  // ALL COLORS OF ISPUI-PROGRESSBAR v2.0.7
  --ispui-progress-bar__custom-color: linear-gradient(
    270deg,
    var(--isp-dc-mass-relay) 0%,
    var(--isp-c-blue-velvet) 50%,
    var(--isp-dc-mass-relay) 100%
  );

  // --ispui-progress-bar__custom-color: linear-gradient(
  //   270deg,
  //   var(--isp-c-fantomas) 0%,
  //   var(--isp-c-shire) 33.3%,
  //   var(--isp-c-happy-bob) 66.6%,
  //   var(--isp-c-mabel-pines) 100%
  // );

  --ispui-progress-bar__rainbow-animation-duration: 5s;
  --ispui-progress-bar__border-radius: 0;
  --ispui-progress-bar__height: 7px;
}
