:root {
  // ngispui-link or ngispui-vtab
  --isp-link__color_regular: var(--isp-dc-fremen);
  --isp-link__color_hover: var(--isp-dc-lapis-lazuli);
  --isp-link__color_active: var(--isp-dc-fremen);

  // ngispui-checkbox
  --ngispui-checkbox__border-color: var(--isp-c-grey-weekdays);
  --ngispui-checkbox__border-color-checked: var(--isp-c-blue-velvet);
  --ngispui-checkbox__icon-check: var(--isp-checkbox-checked-image);
  --ngispui-checkbox__border-color-disabled: var(--isp-c-grey-weekdays);
  --ngispui-checkbox__icon-check-disabled: var(
    --isp-checkbox-checked-image-disabled
  );

  // notifications
  --ngispui-notification-group__top: auto;
  --ngispui-notification-group__bottom: 20px;

  // file
  --ngispui-file-name_color: var(--isp-dc-highway-star);
}

.ngispui-file span.ngispui-file__remove {
  width: 15px;
  height: 15px;

  margin-top: 2px;

  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 6c.28 0 .5.22.5.5v6a.5.5 0 0 1-1 0v-6c0-.28.22-.5.5-.5ZM8 6.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0v-6ZM11 6.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0v-6Z' fill='%23999'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.09 2A1.5 1.5 0 0 1 6.5 0h2a1.5 1.5 0 0 1 1.41 2h1.65A2.44 2.44 0 0 1 14 4.44c0 .3-.25.56-.56.56H13v7.5a2.5 2.5 0 0 1-2.5 2.5h-6A2.5 2.5 0 0 1 2 12.5V5h-.44A.56.56 0 0 1 1 4.44 2.44 2.44 0 0 1 3.44 2h1.65ZM6 1.5c0-.28.22-.5.5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5ZM3.44 3c-.65 0-1.19.42-1.37 1h10.86c-.18-.58-.72-1-1.37-1H3.44ZM3 5h9v7.5c0 .83-.67 1.5-1.5 1.5h-6A1.5 1.5 0 0 1 3 12.5V5Z' fill='%23999'/%3E%3C/svg%3E");
}
